import { Space, Switch, Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { Device } from "../../models/device.entity";
import React from "react";
import DeleteDeviceButton from "../../components/DeleteDeviceButton";
import { useNavigate } from "react-router-dom";
import { generateDeviceDetailPath } from "../../routes";
import dayjs from "dayjs";

const columns: ColumnsType<Device> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enabled MQTT",
    dataIndex: ["mqttUser", "enabled"],
    key: "enabled",
    render: (value, record) => (
      <Switch
        disabled
        checked={value}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked
      />
    ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <DeleteDeviceButton uuid={record.uuid}></DeleteDeviceButton>
      </Space>
    ),
  },
];

const DevicesTable = (props: { data: readonly Device[] }) => {
  const navigate = useNavigate();
  const onRow = (record: Device) => {
    return {
      onClick: (event: any) => {
        navigate(generateDeviceDetailPath(record.uuid));
      }, // click row
    };
  };
  return <Table columns={columns} dataSource={props.data} rowKey={"uuid"} onRow={onRow} />;
};

export default DevicesTable;
