import { ModalProps } from "antd/es/modal/interface";
import { Callbacks } from "rc-field-form/lib/interface";
import { Modal } from "antd";
import PromotionForm from "../PromotionForm/PromotionForm";
import { Promotion } from "../../models/promotion.entity";

type PromotionFormModalProps = Pick<ModalProps, "onCancel" | "open"> & {
  promotion?: Promotion;
  isEdit: boolean;
  submitting: boolean;
  onFinish: Callbacks<Promotion>["onFinish"];
};

// eslint-disable-next-line
const PromotionFormModal = (props: PromotionFormModalProps) => {
  return (
    <Modal
      title={props.isEdit ? "Edit Promotion" : " Create Promotion"}
      open={props.open}
      onCancel={props.onCancel}
      footer={null}
    >
      <PromotionForm
        isEdit={props.isEdit}
        onFinish={props.onFinish}
        submitting={props.submitting}
        promotion={props.promotion}
      ></PromotionForm>
    </Modal>
  );
};

export default PromotionFormModal;
