import { Modal } from "antd";
import { ModalProps } from "antd/es/modal/interface";
import { Callbacks } from "rc-field-form/lib/interface";
import DeviceForm from "../DevicesForm/DevicesForm";
import { Device } from "../../models/device.entity";

type DeviceFormModal = Pick<ModalProps, "onCancel" | "open"> & {
  isEdit: boolean;
  submitting: boolean;
  onFinish: Callbacks<Device>["onFinish"];
};

const DevicesFormModal = (props: DeviceFormModal) => {
  return (
    <Modal
      title={props.isEdit ? "Edit Device" : " Create Device"}
      open={props.open}
      onCancel={props.onCancel}
      footer={null}
    >
      <DeviceForm isEdit={props.isEdit} onFinish={props.onFinish} submitting={props.submitting}></DeviceForm>
    </Modal>
  );
};

export default DevicesFormModal;
