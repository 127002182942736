import { create } from "zustand";
import { Promotion } from "../models/promotion.entity";
import { promotionApi } from "../api/promotion.api";

interface PromotionStore {
  entities: { [key: string]: Promotion };
  getPromotions: (page: number, size: number) => Promise<void>;
  getPromotionByUuid: (uuid: string) => Promise<void>;
  createPromotion: (promotion: Promotion) => Promise<void>;
  updatePromotion: (uuid: string, promotion: Promotion) => Promise<void>;
  deletePromotion: (uuid: string) => Promise<void>;
}

const usePromotionStore = create<PromotionStore>((set, get) => ({
  entities: {},
  getPromotions: async function (pageIndex: number, pageSize: number) {
    const response = await promotionApi.getPromotions(pageIndex, pageSize);
    const promotions = response.data;
    const currentPromotions = get().entities;
    promotions.forEach((prom) => {
      currentPromotions[prom.uuid] = prom;
    });

    set({ entities: currentPromotions });
  },
  getPromotionByUuid: async function (uuid: string) {
    const promotion = await promotionApi.getPromotionByUuid(uuid);
    const currentPromotions = get().entities;
    currentPromotions[promotion.uuid] = promotion;
    set({ entities: currentPromotions });
  },
  createPromotion: async function (promotion: Promotion) {
    const createdPromotion = await promotionApi.createPromotion(promotion);
    const currentPromotions = get().entities;
    currentPromotions[createdPromotion.uuid] = createdPromotion;
    set({ entities: currentPromotions });
  },
  updatePromotion: async function (uuid: string, promotion: Promotion) {
    const updatePromotion = await promotionApi.updatePromotion(uuid, promotion);
    const currentPromotions = get().entities;
    currentPromotions[updatePromotion.uuid] = updatePromotion;
    set({ entities: currentPromotions });
  },
  deletePromotion: async function (uuid) {
    await promotionApi.deletePromotion(uuid);
    const currentPromotions = get().entities;
    delete currentPromotions[uuid];
    set({ entities: currentPromotions });
  },
}));

export default usePromotionStore;
