import { Button, Form, Input, Select } from "antd";
import { MqttAcl, MqttAction, MqttPermission } from "../../models/mqtt-acl.entity";
import { Callbacks } from "rc-field-form/lib/interface";

export interface MqttAclFormValues {
  topic: string;
  permission: MqttPermission;
  action: MqttAction;
}

interface MqttAclFormProp {
  mqttAcl?: MqttAcl;
  isEdit?: boolean;
  onFinish: Callbacks<MqttAclFormValues>["onFinish"];
}

const { Option } = Select;

const MqttAclForm = (prop: MqttAclFormProp) => {
  const [form] = Form.useForm<MqttAclFormValues>();

  return (
    <Form<MqttAclFormValues>
      form={form}
      name="form-mqtt-acl"
      onFinish={prop.onFinish}
      className={"MqttAclForm"}
      scrollToFirstError
    >
      <Form.Item name="topic" required>
        <Input type="text" placeholder="Topic" />
      </Form.Item>
      <Form.Item name="action" required>
        <Select<MqttAction> placeholder="Action">
          <Option value={"publish"}>Publish</Option>
          <Option value={"subscription"}>Subscription</Option>
          <Option value={"all"}>All</Option>
        </Select>
      </Form.Item>
      <Form.Item name="permission" required>
        <Select<MqttPermission> placeholder="Permission">
          <Option value={"allow"}>Allow</Option>
          <Option value={"deny"}>Deny</Option>
        </Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" className="LoginPost" block>
        {prop.isEdit ? "edit" : "create"}
      </Button>
    </Form>
  );
};

export default MqttAclForm;
