import React from "react";
import { Card } from "antd";
import SignInForm from "./SignInForm";
import "./SignInPage.scss";

const SignInPage = () => {
  return (
    <div className="SignInPage">
      <img src="./taiga-logo.svg" alt={""} />
      <Card style={{ width: 400, margin: "20px auto" }}>
        <SignInForm />
      </Card>
    </div>
  );
};

export default SignInPage;
