import { Button, Space } from "antd";
import usePromotionStore from "../../stores/promotion.store";
import { EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import PromotionFormModal from "../../components/PromotionFormModal/PromotionFormModal";

interface UpdatePromotionButtonProps {
  uuid: string;
}

const UpdatePromotionButton = ({ uuid }: UpdatePromotionButtonProps) => {
  const promotion = usePromotionStore((state) => state.entities[uuid]);
  const updatePromotion = usePromotionStore((state) => state.updatePromotion);
  const getPromotions = usePromotionStore((state) => state.getPromotions);

  const [isPromotionModalOpen, setIsPromotionModalOpen] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (value: any) => {
    setSubmitting(true);
    try {
      await updatePromotion(uuid, value);
    } catch (e) {}
    await getPromotions(0, 100);
    setSubmitting(false);
    setIsPromotionModalOpen(false);
  };

  return (
    <Space>
      <Button onClick={() => setIsPromotionModalOpen(true)}>
        <EditOutlined />
      </Button>
      <PromotionFormModal
        submitting={submitting}
        open={isPromotionModalOpen}
        onFinish={onSubmit}
        onCancel={() => setIsPromotionModalOpen(false)}
        promotion={promotion}
        isEdit={true}
      ></PromotionFormModal>
    </Space>
  );
};

export default UpdatePromotionButton;
