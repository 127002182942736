import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import useDeviceStore from "../stores/device.store";

const { confirm } = Modal;

interface DeleteDeviceButtonProps {
  uuid: string;
  onDeleted?: () => void;
}

const DeleteDeviceButton = ({ uuid, onDeleted }: DeleteDeviceButtonProps) => {
  const deleteDevice = useDeviceStore((state) => state.deleteDevice);
  const getDevices = useDeviceStore((state) => state.getDevices);
  const onClick = async (e: any) => {
    e.stopPropagation();
    confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        await deleteDevice(uuid);
        await getDevices(0, 100);
        if (onDeleted) {
          onDeleted();
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Space>
      <Button onClick={onClick} danger>
        <DeleteOutlined />
      </Button>
    </Space>
  );
};

export default DeleteDeviceButton;
