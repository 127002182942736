import { message, QRCode, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import dayjs from "dayjs";
import { Dispenser } from "../../models/dispenser.entity";
import DeleteDispenserButton from "../../components/DeleteDispenserButton";
import DispenserFormModal from "../../components/DispenserFormModal/DispenserFormModal";
import useDispenserStore from "../../stores/dispenser.store";
import { Device } from "../../models/device.entity";
import { Promotion } from "../../models/promotion.entity";

const columns: ColumnsType<Dispenser> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Location",
    dataIndex: "address",
    key: "location",
    render: (_, record) => (
      <span>
        {record.address}, {record.latitude}, {record.longitude}
      </span>
    ),
  },
  {
    title: "QR",
    dataIndex: "qrCode",
    key: "QR",
    render: (_, record) => (
      <span>
        <QRCode value={record.qrCode || "-"} />
      </span>
    ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <DeleteDispenserButton uuid={record.uuid}></DeleteDispenserButton>
      </Space>
    ),
  },
];

export type DispenserTableProps = {
  devices: Device[];
  promos: Promotion[];
  data: readonly Dispenser[];
  loading: boolean;
};

const DispenserTable = (props: DispenserTableProps) => {
  const [submitting, setSubmitting] = useState(false);
  const updateDispenser = useDispenserStore((state) => state.updateDispenser);
  const updateDispenserDevice = useDispenserStore((state) => state.updateDispenserDevice);
  const [editDispenser, setEditDispenser] = useState<Dispenser | undefined>(undefined);

  const onRow = (record: Dispenser) => {
    return {
      onClick: (event: any) => {
        setEditDispenser(record);
      },
    };
  };

  const onEdit = async (values: Dispenser) => {
    setSubmitting(true);
    if (editDispenser?.uuid) {
      await updateDispenser(editDispenser.uuid, values);
      if (editDispenser.deviceUuid !== values.deviceUuid) {
        await updateDispenserDevice(values.deviceUuid, editDispenser.uuid);
      }
      message.success(`Dispenser ${values.name} updated`);
      setEditDispenser(undefined);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Table loading={props.loading} columns={columns} dataSource={props.data} rowKey={"uuid"} onRow={onRow} />
      <DispenserFormModal
        dispenser={editDispenser}
        devices={props.devices}
        promos={props.promos}
        isEdit={true}
        submitting={submitting}
        onFinish={onEdit}
        onCancel={() => setEditDispenser(undefined)}
        open={!!editDispenser}
      ></DispenserFormModal>
    </>
  );
};

export default DispenserTable;
