import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal, Space } from "antd";
import useDispenserStore from "../stores/dispenser.store";

const { confirm } = Modal;

interface DeleteDispenserButtonProps {
  uuid: string;
  onDeleted?: () => void;
}

const DeleteDispenserButton = ({ uuid, onDeleted }: DeleteDispenserButtonProps) => {
  const dispenser = useDispenserStore((state) => state.entities[uuid]);
  const deleteDispenser = useDispenserStore((state) => state.deleteDispenser);
  const getDispensers = useDispenserStore((state) => state.getDispensers);
  const onClick = async (e: any) => {
    e.stopPropagation();
    confirm({
      title: `Do you Want to delete these dispenser ${dispenser.name}?`,
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        await deleteDispenser(uuid);
        await getDispensers(0, 100);
        message.success(`Dispenser ${dispenser.name} deleted`);
        if (onDeleted) {
          onDeleted();
        }
      },
    });
  };

  return (
    <Space>
      <Button onClick={onClick} danger>
        <DeleteOutlined />
      </Button>
    </Space>
  );
};

export default DeleteDispenserButton;
