import React from "react";
import { Menu, MenuProps } from "antd";
import { devicesPath, dispenserPath, promotionPath } from "../../routes";
import "./PrivatePageSideBar.scss";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const privatePageSideBar = () => {
  const items: MenuItem[] = [
    getItem(<a href={dispenserPath}>Dispensers</a>, "dispensers"),
    getItem(<a href={devicesPath}>BPUs</a>, "bpus"),
    getItem(<a href={promotionPath}>Promotions</a>, "promotion"),
  ];

  return (
    <div className="PrivatePageSideBar">
      <Menu mode="inline" items={items} />
      <div className="PrivatePageSideBar_footer">v{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default privatePageSideBar;
