import { create } from "zustand";
import { Dispenser } from "../models/dispenser.entity";
import { dispenserApi } from "../api/dispenser.api";

interface DispenserStore {
  entities: { [key: string]: Dispenser };
  getDispensers: (page: number, size: number) => Promise<void>;
  getDispenserByUuid: (uuid: string) => Promise<void>;
  createDispenser: (dispenser: Dispenser) => Promise<void>;
  updateDispenser: (uuid: string, dispenser: Dispenser) => Promise<void>;
  updateDispenserDevice: (uuid: string, dispenser: string) => Promise<void>;
  deleteDispenser: (uuid: string) => Promise<void>;
}

const useDispenserStore = create<DispenserStore>((set, get) => ({
  entities: {},
  getDispensers: async function (pageIndex: number, pageSize: number) {
    const dispensers = (await dispenserApi.getDispensers(pageIndex, pageSize)).data;
    const currentDispensers = get().entities;

    dispensers.forEach((dispenser) => {
      currentDispensers[dispenser.uuid] = dispenser;
    });

    set({ entities: currentDispensers });
  },
  getDispenserByUuid: async function (uuid: string) {
    const dispenser = await dispenserApi.getDispenserByUuid(uuid);
    const currentDispensers = get().entities;
    currentDispensers[dispenser.uuid] = dispenser;
    set({ entities: currentDispensers });
  },
  createDispenser: async function (dispenser: Dispenser) {
    const createdDispenser = await dispenserApi.createDispenser(dispenser);
    const currentDispensers = get().entities;
    currentDispensers[createdDispenser.uuid] = createdDispenser;
    set({ entities: currentDispensers });
  },
  updateDispenser: async function (uuid: string, dispenser: Dispenser) {
    const updateDispenser = await dispenserApi.updateDispenser(uuid, dispenser);
    const currentDispensers = get().entities;
    currentDispensers[updateDispenser.uuid] = updateDispenser;
    set({ entities: currentDispensers });
  },
  updateDispenserDevice: async function (uuid: string, deviceUuid: string) {
    const updateDispenser = await dispenserApi.updateDispenserDeviceUuid(uuid, deviceUuid);
    const currentDispensers = get().entities;
    currentDispensers[updateDispenser.uuid] = updateDispenser;
    set({ entities: currentDispensers });
  },
  deleteDispenser: async function (uuid) {
    await dispenserApi.deleteDispenser(uuid);
    const currentDispensers = get().entities;
    delete currentDispensers[uuid];
    set({ entities: currentDispensers });
  },
}));

export default useDispenserStore;
