import { Button, Form, Input, InputNumber, Select, Space, Switch } from "antd";
import { Callbacks } from "rc-field-form/lib/interface";
import { Dispenser } from "../../models/dispenser.entity";
import { Device } from "../../models/device.entity";
import React from "react";
import { Promotion } from "../../models/promotion.entity";

const colorOptions = ["red", "green", "blue", "purple"];

interface DispenserFormProps {
  dispenser?: Dispenser;
  devices: Device[];
  promos: Promotion[];
  isEdit?: boolean;
  submitting: boolean;
  onFinish: Callbacks<Dispenser>["onFinish"];
}

const DispenserForm = (props: DispenserFormProps) => {
  const [form] = Form.useForm<Dispenser>();

  const selectDeviceOptions = props.devices.map((device) => {
    return (
      <Select.Option key={device.uuid} value={device.uuid}>
        {device.name}
      </Select.Option>
    );
  });

  const selectPromotionOptions = props.promos.map((promo) => {
    return (
      <Select.Option key={promo.uuid} value={promo.uuid}>
        {promo.name}
      </Select.Option>
    );
  });

  const selectColorOptions = colorOptions.map((color) => {
    return (
      <Select.Option key={color} value={color}>
        {color}
      </Select.Option>
    );
  });

  return (
    <Form<Dispenser>
      initialValues={props.dispenser}
      form={form}
      name="device-form"
      layout="horizontal"
      onFinish={props.onFinish}
      className={"MqttAclForm"}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="address" label="Address" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="latitude" label="Latitude" rules={[{ required: true }]}>
        <InputNumber type="number" />
      </Form.Item>
      <Form.Item name="longitude" label="Longitude" rules={[{ required: true }]}>
        <InputNumber type="number" />
      </Form.Item>
      <Form.Item name="deviceUuid" label="Device" rules={[{ required: true }]}>
        <Select>{selectDeviceOptions}</Select>
      </Form.Item>
      <Space>
        <Form.Item name="isPublic" label="Is Public">
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item name="qrUrl" label="QR URL" rules={[{ required: true }]}>
        <Input type={"text"} />
      </Form.Item>
      <Form.Item name="color" label="Colors" rules={[{ required: true, type: "array" }]}>
        <Select mode="multiple">{selectColorOptions}</Select>
      </Form.Item>
      <Form.Item name="promotionUuid" label="Promotion" rules={[{ required: true }]}>
        <Select>{selectPromotionOptions}</Select>
      </Form.Item>
      <Form.Item name="enablePromotion" label="Enable Promotion">
        <Switch />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="LoginPost" loading={props.submitting} block>
        Submit
      </Button>
    </Form>
  );
};

export default DispenserForm;
