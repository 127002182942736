import React, { useState } from "react";
import { Button, Card, Col, Row, Switch } from "antd";
import { MqttUser } from "../../models/mqtt-user.entity";
import MqttAclModal from "../../components/MqttAclModal/MqttAclModal";

interface MqttSectionProps {
  mqttUser: MqttUser;
}

const MqttSection = (props: MqttSectionProps) => {
  const [isOpenAclModal, setIsOpenAclModal] = useState(false);

  return (
    <Card title={"MQTT"}>
      <Row>
        <Col flex={1}>MQTT User: {props.mqttUser.username}</Col>
        <Col flex={1}>
          <span>Enabled: </span>
          <Switch checked={props.mqttUser.enabled} disabled />
        </Col>
      </Row>

      <br />
      <Button type={"primary"} onClick={() => setIsOpenAclModal(true)}>
        Add ACL
      </Button>
      <MqttAclModal
        isEdit={false}
        onFinish={() => {}}
        open={isOpenAclModal}
        onCancel={() => setIsOpenAclModal(false)}
      ></MqttAclModal>
    </Card>
  );
};

export default MqttSection;
