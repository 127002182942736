import { ColumnsType } from "antd/es/table";
import { PaginationProps, Space, Table } from "antd";
import { Promotion } from "../../models/promotion.entity";
import DeletePromotionButton from "./DeletePromotionButton";
import UpdatePromotionButton from "./UpdatePromotionButton";

const columns: ColumnsType<Promotion> = [
  {
    title: "Uuid",
    dataIndex: "uuid",
    key: "uuid",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Image URL",
    dataIndex: "imageUrl",
    key: "imageUrl",
  },

  {
    title: "Legal Terms",
    dataIndex: "legalTerms",
    key: "legalTerms",
  },
  {
    title: "Update",
    key: "update",
    render: (_, record) => (
      <Space size="middle">
        <UpdatePromotionButton uuid={record.uuid}></UpdatePromotionButton>
      </Space>
    ),
  },
  {
    title: "Delete",
    key: "delete",
    render: (_, record) => (
      <Space size="middle">
        <DeletePromotionButton uuid={record.uuid}></DeletePromotionButton>
      </Space>
    ),
  },
];

type PromotionTableProps = {
  data: readonly Promotion[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const PromotionTable = (props: PromotionTableProps) => (
  <Table
    columns={columns}
    dataSource={props.data}
    rowKey={(record) => record.uuid}
    /*pagination={props.pagination}
        onChange={props.onChange}*/
  />
);

//const PromotionTable = (props: { data: readonly Promotion[] }) => <Table columns={columns} dataSource={props.data} />;

export default PromotionTable;
