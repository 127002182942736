import { Modal } from "antd";
import { ModalProps } from "antd/es/modal/interface";
import { Callbacks } from "rc-field-form/lib/interface";
import DispenserForm from "../DispenserForm/DispenserForm";
import { Dispenser } from "../../models/dispenser.entity";
import { Device } from "../../models/device.entity";
import { Promotion } from "../../models/promotion.entity";

type DispenserFormModal = Pick<ModalProps, "onCancel" | "open"> & {
  dispenser?: Dispenser;
  devices: Device[];
  promos: Promotion[];
  isEdit: boolean;
  submitting: boolean;
  onFinish: Callbacks<Dispenser>["onFinish"];
};

const DevicesFormModal = (props: DispenserFormModal) => {
  return (
    <Modal
      title={props.isEdit ? "Edit Dispenser" : " Create Dispenser"}
      open={props.open}
      onCancel={props.onCancel}
      footer={null}
    >
      <DispenserForm
        isEdit={props.isEdit}
        onFinish={props.onFinish}
        submitting={props.submitting}
        dispenser={props.dispenser}
        devices={props.devices}
        promos={props.promos}
      ></DispenserForm>
    </Modal>
  );
};

export default DevicesFormModal;
