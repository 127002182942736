import React, { useEffect, useState } from "react";
import { Button, Card, Descriptions, DescriptionsProps, Input, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import "./DeviceDetailPage.scss";
import MqttSection from "./MqttSection";
import useDeviceStore from "../../stores/device.store";
import { useNavigate, useParams } from "react-router-dom";
import DeleteDeviceButton from "../../components/DeleteDeviceButton";
import { devicesPath } from "../../routes";
import DeviceDataDownloadModal from "./DeviceDataDownloadModal";
import { LoadingOutlined } from "@ant-design/icons";
import ShadowSection from "./ShadowSection";

const DeviceDetailPage = () => {
  let { deviceUuid } = useParams();
  const navigate = useNavigate();
  const getDeviceByUuid = useDeviceStore((state) => state.getDeviceByUuid);
  const [isDownloadDataModalOpen, setIsDownloadDataModalOpen] = useState(false);


  useEffect(() => {
    if (deviceUuid) {
      getDeviceByUuid(deviceUuid!);
    }
  }, [deviceUuid, getDeviceByUuid]);

  const device = useDeviceStore((state) => state.entities[deviceUuid!]);

  const items: DescriptionsProps["items"] = [
    { key: "deviceUuid", label: "Device UUID", children: <span>{device?.uuid}</span> },
    { key: "cpuSerial", label: "Cpu Serial", children: <span>{device?.data.cpuSerial}</span> },
    { key: "cm4Model", label: "CM4 Model", children: <span>{device?.data.cm4Model}</span> },
    { key: "simIccid", label: "SIM ICCID", children: <span>{device?.data.simIccid}</span> },
    {
      key: "embeddedHardwareVersion",
      label: "Embedded Hardware Version",
      children: <span>{device?.data.embeddedHardwareVersion}</span>,
    },
    {
      key: "embeddedSoftwareVersion",
      label: "Embedded Software Version",
      children: <span>{device?.data.embeddedSoftwareVersion}</span>,
    },
    { key: "osUsername", label: "OS Username", children: <span>{device?.data.osUsername}</span> },
    {
      key: "osPassword",
      label: "OS Password",
      children: (
        <Input.Password value={device?.data.osPassword} bordered={false} visibilityToggle={false}></Input.Password>
      ),
    },
    { key: "wifiSsid", label: "WIFI SSID", children: <span>{device?.data.wifiSsid}</span> },
    {
      key: "wifiPassword",
      label: "WIFI Password",
      children: (
        <Input.Password value={device?.data.wifiPassword} bordered={false} visibilityToggle={false}></Input.Password>
      ),
    },
    { key: "hostname", label: "Hostname", children: <span>{device?.data.hostname}</span> },
    { key: "comment", label: "Comment", children: <span>{device?.data.comment}</span> },
  ];

  return (
    <Content className="DeviceDetailPage">
      <Space direction={"vertical"} size={"middle"}>
        <Card title={device?.name || "Name Undefined"}>
          {device ? (
            <>
              <Space>
                <Button type={"primary"} onClick={() => setIsDownloadDataModalOpen(true)}>
                  {" "}
                  Download Data (CSV){" "}
                </Button>
                <DeviceDataDownloadModal
                  open={isDownloadDataModalOpen}
                  device={device}
                  onCancel={() => setIsDownloadDataModalOpen(false)}
                ></DeviceDataDownloadModal>
                <DeleteDeviceButton uuid={deviceUuid!} onDeleted={() => navigate(devicesPath)}></DeleteDeviceButton>
              </Space>
              <br />
              <br />
              <Descriptions items={items} column={2} />
            </>
          ) : (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          )}
        </Card>
        {device?.mqttUser ? <MqttSection mqttUser={device.mqttUser}></MqttSection> : null}

        <ShadowSection uuid={device.uuid}></ShadowSection>
      </Space>
    </Content>
  );
};

export default DeviceDetailPage;
