import { Outlet } from "react-router";
import PrivatePageHeader from "./PrivatePageHeader";
import PrivatePageSideBar from "./PrivatePageSideBar";
import "./PrivatePage.scss";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../stores/auth.store";
import { useEffect } from "react";
import { signInPath } from "../../routes";

const PrivatePage = () => {
  const navigate = useNavigate();
  const authToken = useAuthStore((state) => state.authToken);
  useEffect(() => {
    if (!authToken) {
      navigate(signInPath, { replace: true });
    }
  }, [authToken, navigate]);

  return (
    <Layout className={"PrivatePage"}>
      <PrivatePageHeader />
      <div className={"PrivatePage_content"}>
        <PrivatePageSideBar />
        <Outlet />
      </div>
    </Layout>
  );
};

export default PrivatePage;
