import { apiV1 } from "./api-v1";
import { Promotion } from "../models/promotion.entity";
import { PaginationResult } from "../models/pagination";

export const promotionApi = {
  createPromotion: async (promotion: Promotion): Promise<Promotion> => {
    const result = await apiV1.post("promotions", promotion);
    return result.data;
  },
  updatePromotion: async (uuid: string, promotion: Promotion): Promise<Promotion> => {
    console.log(promotion);
    const result = await apiV1.put<Promotion>("promotions/" + uuid, promotion);
    return result.data;
  },
  getPromotionByUuid: async (uuid: string): Promise<Promotion> => {
    const result = await apiV1.get(`promotions/${uuid}`);
    return result.data;
  },
  getPromotions: async (pageIndex: number, pageSize: number): Promise<PaginationResult<Promotion>> => {
    const result = await apiV1.get("promotions", { params: { pageIndex, pageSize } });
    return result.data;
  },
  deletePromotion: async (uuid: string): Promise<void> => {
    return await apiV1.delete(`promotions/${uuid}`);
  },
};
