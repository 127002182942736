import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import DevicesFormModal from "../../components/DeviceFormModal/DeviceFormModal";
import useDeviceStore from "../../stores/device.store";
import DevicesTable from "./DevicesTable";

const { Title } = Typography;

const FountainPage = () => {
  const getDevices = useDeviceStore((state) => state.getDevices);
  const createDevice = useDeviceStore((state) => state.createDevice);
  const devices = useDeviceStore((state) => Object.values(state.entities));
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getDevices(0, 100);
  }, [getDevices]);

  const onSubmit = async (value: any) => {
    setSubmitting(true);
    await createDevice(value);
    getDevices(0, 100);
    setSubmitting(false);
    setIsDeviceModalOpen(false);
  };

  return (
    <Content className="DeviceDetailPage">
      <Title>BPUs</Title>
      <Button type={"primary"} onClick={() => setIsDeviceModalOpen(true)}>
        Add New Device
      </Button>
      <DevicesFormModal
        submitting={submitting}
        open={isDeviceModalOpen}
        onFinish={onSubmit}
        onCancel={() => setIsDeviceModalOpen(false)}
        isEdit={false}
      ></DevicesFormModal>

      <br />
      <br />

      <DevicesTable data={devices}></DevicesTable>
    </Content>
  );
};

export default FountainPage;
