import { Button, Form, Input } from "antd";
import { Callbacks } from "rc-field-form/lib/interface";
import { Device } from "../../models/device.entity";

interface DeviceFormProps {
  mqttAcl?: Device;
  isEdit?: boolean;
  submitting: boolean;
  onFinish: Callbacks<Device>["onFinish"];
}

const DeviceForm = (props: DeviceFormProps) => {
  const [form] = Form.useForm<Device>();

  return (
    <Form<Device>
      form={form}
      name="device-form"
      layout="horizontal"
      onFinish={props.onFinish}
      className={"MqttAclForm"}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "cpuSerial"]} label="CPU Serial">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "cm4Model"]} label="CM4 Model">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "simIccid"]} label="SIM ICCID">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "embeddedHardwareVersion"]} label="Hardware Version">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "embeddedSoftwareVersion"]} label="Software Version">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "hostname"]} label="Hostname">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "osUsername"]} label="OS Username">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "osPassword"]} label="OS Password">
        <Input.Password type="text" />
      </Form.Item>
      <Form.Item name={["data", "wifiSsid"]} label="WIFI SSID">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "wifiPassword"]} label="WIFI Password">
        <Input.Password type="text" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="LoginPost" loading={props.submitting} block>
        {props.isEdit ? "edit" : "create"}
      </Button>
    </Form>
  );
};

export default DeviceForm;
