import { Modal } from "antd";
import MqttAclForm, { MqttAclFormValues } from "../MqttAclForm/MqttAclForm";
import { ModalProps } from "antd/es/modal/interface";
import { Callbacks } from "rc-field-form/lib/interface";

type MqttAclModalProps = Pick<ModalProps, "onCancel" | "open"> & {
  isEdit: boolean;
  onFinish: Callbacks<MqttAclFormValues>["onFinish"];
};

const MqttAclModal = (props: MqttAclModalProps) => {
  return (
    <Modal title={props.isEdit ? "Edit ACL" : " Create ACL"} open={props.open} onCancel={props.onCancel}>
      <br />
      <MqttAclForm isEdit={props.isEdit} onFinish={props.onFinish}></MqttAclForm>
    </Modal>
  );
};

export default MqttAclModal;
