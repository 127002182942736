import React, { useEffect, useState } from "react";
import { Button, message, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import usePromotionStore from "../../stores/promotion.store";
import PromotionFormModal from "../../components/PromotionFormModal/PromotionFormModal";
import PromotionTable from "./PromotionTable";
import "./PromotionPage.scss";

const { Title } = Typography;

const PROMOTIONS_PAGE_SIZE = 100;

const PromotionPage = () => {
  // let page = 0;
  const [, setLoadingPromotions] = useState(false);
  const getPromotions = usePromotionStore((state) => state.getPromotions);
  const createPromotion = usePromotionStore((state) => state.createPromotion);

  const promotions = usePromotionStore((state) => Object.values(state.entities));
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setLoadingPromotions(true);
    getPromotions(0, 100)
      .finally(() => {
        setLoadingPromotions(false);
      })
      .then(() => {
        message.success("Dispenser list Loaded");
      });
  }, [getPromotions]);

  const onSubmit = async (value: any) => {
    setSubmitting(true);
    try {
      await createPromotion(value);
    } catch (e) {}
    await getPromotions(0, 100);
    setSubmitting(false);
    setIsPromotionModalOpen(false);
  };

  return (
    <Content className="PromotionPage">
      <Title>Promotions</Title>
      <Button type={"primary"} onClick={() => setIsPromotionModalOpen(true)}>
        Add New Promotion
      </Button>
      <PromotionFormModal
        submitting={submitting}
        open={isPromotionModalOpen}
        onFinish={onSubmit}
        onCancel={() => setIsPromotionModalOpen(false)}
        isEdit={false}
      ></PromotionFormModal>

      <br />
      <br />

      <PromotionTable
        data={promotions}
        pagination={{
          pageSize: PROMOTIONS_PAGE_SIZE,
          total: 1000,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getPromotions(pagination.current! - 1, PROMOTIONS_PAGE_SIZE).then(() => {
            // page = pagination.current! - 1;
          });
        }}
      />
    </Content>
  );
};

export default PromotionPage;
