import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Button, message, Typography } from "antd";
import "./DispenserPage.scss";
import DispenserFormModal from "../../components/DispenserFormModal/DispenserFormModal";
import useDispenserStore from "../../stores/dispenser.store";
import DispenserTable from "./DispenserTable";
import useDeviceStore from "../../stores/device.store";
import usePromotionStore from "../../stores/promotion.store";

const { Title } = Typography;

const DispenserPage = () => {
  const [loadingDispensers, setLoadingDispensers] = useState(false);
  const getDispensers = useDispenserStore((state) => state.getDispensers);
  const createDevice = useDispenserStore((state) => state.createDispenser);
  const dispensers = useDispenserStore((state) => Object.values(state.entities));
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const getDevices = useDeviceStore((state) => state.getDevices);
  const devices = useDeviceStore((state) => Object.values(state.entities));

  const getPromotions = usePromotionStore((state) => state.getPromotions);
  const promotions = usePromotionStore((state) => Object.values(state.entities));

  useEffect(() => {
    setLoadingDispensers(true);
    getDispensers(0, 100)
      .finally(() => {
        setLoadingDispensers(false);
      })
      .then(() => {
        message.success("Dispenser list Loaded");
      });
  }, [getDispensers]);

  useEffect(() => {
    getDevices(0, 100);
  }, [getDevices]);

  useEffect(() => {
    getPromotions(0, 100);
  }, [getPromotions]);

  const onSubmit = async (value: any) => {
    setSubmitting(true);
    try {
      await createDevice(value);
    } catch (e) {}
    getDispensers(0, 100);
    setSubmitting(false);
    setIsDeviceModalOpen(false);
  };

  return (
    <Content className="DispenserPage">
      <Title>Dispenser</Title>
      <Button type={"primary"} onClick={() => setIsDeviceModalOpen(true)}>
        Add New Device
      </Button>
      <DispenserFormModal
        submitting={submitting}
        open={isDeviceModalOpen}
        onFinish={onSubmit}
        onCancel={() => setIsDeviceModalOpen(false)}
        isEdit={false}
        devices={devices}
        promos={promotions}
      ></DispenserFormModal>

      <br />
      <br />

      <DispenserTable
        devices={devices}
        loading={loadingDispensers}
        data={dispensers}
        promos={promotions}
      ></DispenserTable>
    </Content>
  );
};

export default DispenserPage;
