import React, { useEffect } from "react";
import { Button, Card, Space } from "antd";
import useDeviceStore from "../../stores/device.store";

interface ShadowSectionProps {
  uuid: string;
}

const ShadowSection = (props: ShadowSectionProps)  => {

  const changeMode = useDeviceStore((state) => state.changeMode);
  const getMode = useDeviceStore((state) => state.getShadowByDeviceUuid);
  const shadow = useDeviceStore((state) => state.shadows[props.uuid]);

  useEffect(() => {
    getMode(props.uuid);

    // Update data every 10 seconds
    const interval = setInterval(() => {
      getMode(props.uuid);
    }, 10000); // 10 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [getMode, props.uuid]);

  return (
    <Card title={"Shadow"}>
      <Space>
        <Button type={shadow?.data?.state.reported?.mode === "FREE" ? "primary" : "default"}
                onClick={() => changeMode(props.uuid, "FREE")}>
          {"Free"}
        </Button>

        <Button type={shadow?.data?.state.reported?.mode === "PRE-REGISTER ACTIVE" ? "primary" : "default"}
                onClick={() => changeMode(props.uuid, "PRE-REGISTER ACTIVE")}>
          {"Pre-register active"}
        </Button>

        <Button type={shadow?.data?.state.reported?.mode === "PRE-REGISTER NOT ACTIVE" ? "primary" : "default"}
                onClick={() => changeMode(props.uuid, "PRE-REGISTER NOT ACTIVE")}>
          {"Pre-register not active"}
        </Button>

        <Button type={shadow?.data?.state.reported?.mode === "NOT CONFIGURED" ? "primary" : "default"}
                onClick={() => changeMode(props.uuid, "NOT CONFIGURED")}>
          {"Not configured"}
        </Button>

      </Space>

      <br />
      <pre>{JSON.stringify(shadow, null, 2)}</pre>

    </Card>
  )
}

export default ShadowSection;