import { apiV1 } from "./api-v1";
import { Device } from "../models/device.entity";
import dayjs from "dayjs";
import { PaginationResult } from "../models/pagination";
import { Shadow } from "../models/shadow";

export const deviceApi = {
  createDevice: async (device: Device): Promise<Device> => {
    return await apiV1.post("devices", device);
  },
  getDeviceByUuid: async (uuid: string): Promise<Device> => {
    const result = await apiV1.get(`devices/${uuid}`);
    return result.data;
  },
  getDevices: async (pageIndex: number, pageSize: number): Promise<PaginationResult<Device>> => {
    const result = await apiV1.get("devices", { params: { pageIndex, pageSize } });
    return result.data;
  },
  deleteDevice: async (uuid: string): Promise<void> => {
    return await apiV1.delete(`devices/${uuid}`);
  },
  getDownloadDeviceData: async (uuid: string, sensorType: string, start: number, end: number) => {
    const response = await apiV1.get(`devices/${uuid}/data-csv`, {
      params: { sensorType, start, end },
      responseType: "blob",
    });

    const startDateString = dayjs(start).format("YYYY-MM-DD");
    const endDateString = dayjs(end).format("YYYY-MM-DD");

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `bpu-data-${startDateString}-${endDateString}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return response.data;
  },
  changeShadow(uuid: string, mode: string) {
    return apiV1.post(`devices/mode/${uuid}`, { mode });
  },
  getShadowByDeviceUuid: async (uuid: string) => {
    const result = await apiV1.get<Shadow>(`devices/mode/${uuid}`);
    return result.data;
  }
};
