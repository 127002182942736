import { create } from "zustand";
import { Device } from "../models/device.entity";
import { deviceApi } from "../api/device.api";
import { Shadow } from "../models/shadow";

interface DeviceStore {
  entities: { [key: string]: Device };
  shadows: {[key: string]: Shadow};
  getDevices: (page: number, size: number) => void;
  getDeviceByUuid: (uuid: string) => void;
  createDevice: (device: Device) => void;
  deleteDevice: (uuid: string) => void;
  changeMode: (uuid: string, mode: string) => void;
  getShadowByDeviceUuid: (uuid: string) => void;
}


const useDeviceStore = create<DeviceStore>((set, get) => ({
  entities: {},
  shadows: {},
  getDevices: async function (pageIndex: number, pageSize: number) {
    const devices = (await deviceApi.getDevices(pageIndex, pageSize)).data;
    const currentDevices = get().entities;

    devices.forEach((device) => {
      currentDevices[device.uuid] = device;
    });

    set({ entities: currentDevices });
  },
  getDeviceByUuid: async function (uuid: string) {
    const device = await deviceApi.getDeviceByUuid(uuid);
    const currentDevices = get().entities;
    currentDevices[device.uuid] = device;
    set({ entities: currentDevices });
  },
  createDevice: async function (device: Device) {
    await deviceApi.createDevice(device);
  },
  deleteDevice: async function (uuid) {
    await deviceApi.deleteDevice(uuid);
    const currentDevices = get().entities;
    delete currentDevices[uuid];
    set({ entities: currentDevices });
  },

  changeMode: async function (uuid: string, mode: string) {
    await deviceApi.changeShadow(uuid, mode);
  },

  getShadowByDeviceUuid: async function (uuid: string) {
    const shadow = await deviceApi.getShadowByDeviceUuid(uuid);
    const currentShadow = get().shadows;
    currentShadow[uuid] = shadow;
    set({ shadows: currentShadow });
    return shadow;
  }
}));

export default useDeviceStore;
