import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal, Space } from "antd";
import usePromotionStore from "../../stores/promotion.store";

const { confirm } = Modal;

interface DeletePromotionButtonProps {
  uuid: string;
  onDeleted?: () => void;
}

const DeletePromotionButton = ({ uuid, onDeleted }: DeletePromotionButtonProps) => {
  const promotion = usePromotionStore((state) => state.entities[uuid]);
  const deletePromotion = usePromotionStore((state) => state.deletePromotion);
  const getPromotions = usePromotionStore((state) => state.getPromotions);
  const onClick = async (e: any) => {
    e.stopPropagation();
    confirm({
      title: `Do you Want to delete these dispenser ${promotion.name}?`,
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        await deletePromotion(uuid);
        await getPromotions(0, 100);
        message.success(`Dispenser ${promotion.name} deleted`);
        if (onDeleted) {
          onDeleted();
        }
      },
    });
  };

  return (
    <Space>
      <Button onClick={onClick} danger>
        <DeleteOutlined />
      </Button>
    </Space>
  );
};

export default DeletePromotionButton;
