import { AxiosResponse } from "axios";
import { apiV1 } from "./api-v1";
import { Auth } from "../models/auth";

export type LoginRequestData = {
  email: string;
  password: string;
};

export type RegisterRequestData = {
  email: string;
  password: string;
  username: string;
};

export const authApi = {
  signIn: async function (params: LoginRequestData): Promise<AxiosResponse<Auth>> {
    return await apiV1.post<LoginRequestData, AxiosResponse<Auth>>("auth/sign-in", {
      email: params.email,
      password: params.password,
    });
  },

  register: async function (params: RegisterRequestData): Promise<AxiosResponse<Auth>> {
    return await apiV1.post<RegisterRequestData, AxiosResponse<Auth>>("auth/user/register", {
      profile: {
        email: params.email,
        username: params.username,
      },
      password: params.password,
    });
  },
};
