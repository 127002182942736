import { Callbacks } from "rc-field-form/lib/interface";
import { Button, Form, Input } from "antd";
import { Promotion } from "../../models/promotion.entity";

interface PromotionFormProps {
  promotion?: Promotion;
  isEdit?: boolean;
  submitting: boolean;
  onFinish: Callbacks<Promotion>["onFinish"];
}

const PromotionForm = (props: PromotionFormProps) => {
  const [form] = Form.useForm<Promotion>();

  return (
    <Form<Promotion>
      initialValues={props.promotion}
      form={form}
      name="device-form"
      layout="horizontal"
      onFinish={props.onFinish}
      className={"PromotionForm"}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="imageUrl" label="Image URL" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="legalTerms" label="Legal Terms" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="PromotionForm" loading={props.submitting} block>
        Submit
      </Button>
    </Form>
  );
};

export default PromotionForm;
