import { apiV1 } from "./api-v1";
import { Dispenser } from "../models/dispenser.entity";
import { PaginationResult } from "../models/pagination";

export const dispenserApi = {
  createDispenser: async (dispenser: Dispenser): Promise<Dispenser> => {
    const result = await apiV1.post("dispensers", dispenser);
    return result.data;
  },
  updateDispenser: async (uuid: string, dispenser: Dispenser): Promise<Dispenser> => {
    const result = await apiV1.put<Dispenser>("dispensers/" + uuid, dispenser);
    return result.data;
  },
  updateDispenserDeviceUuid: async (uuid: string, dispenserUuid: string): Promise<Dispenser> => {
    const result = await apiV1.put<Dispenser>(`/dispensers/${dispenserUuid}/deviceUuid`, {
      deviceUuid: uuid,
    });
    return result.data;
  },
  getDispenserByUuid: async (uuid: string): Promise<Dispenser> => {
    const result = await apiV1.get(`dispensers/${uuid}`);
    return result.data;
  },
  getDispensers: async (pageIndex: number, pageSize: number): Promise<PaginationResult<Dispenser>> => {
    const result = await apiV1.get("dispensers", { params: { pageIndex, pageSize } });
    return result.data;
  },
  deleteDispenser: async (uuid: string): Promise<void> => {
    return await apiV1.delete(`dispensers/${uuid}`);
  },
};
