import React, { useState } from "react";
import { Button, DatePicker, Modal, Radio, Space } from "antd";
import { ModalProps } from "antd/es/modal/interface";
import { Device } from "../../models/device.entity";
import dayjs, { Dayjs } from "dayjs";
import { deviceApi } from "../../api/device.api";

const { RangePicker } = DatePicker;

type DeviceDataDownloadModalProps = Pick<ModalProps, "onCancel" | "open"> & {
  device: Device;
};

type FieldType = {
  startTime: Dayjs;
  endTime: Dayjs;
  uuid: string;
};

type DateButtonValue = "lastDay" | "lastWeek" | "lastMonth" | "lastYear";
const LastDay: DateButtonValue = "lastDay";
const lastWeek: DateButtonValue = "lastWeek";
const lastMonth: DateButtonValue = "lastMonth";
const lastYear: DateButtonValue = "lastYear";

const DeviceDataDownloadModal = (props: DeviceDataDownloadModalProps) => {
  const [field, setField] = useState<FieldType>({
    startTime: dayjs(),
    endTime: dayjs(),
    uuid: props.device.uuid,
  });

  const onClickButton = (event: any) => {
    let startTime: Dayjs = dayjs();
    let endTime: Dayjs = dayjs();
    if (event.target.value === LastDay) {
      startTime = dayjs().subtract(1, "day");
      endTime = dayjs();
    }

    if (event.target.value === lastWeek) {
      startTime = dayjs().subtract(1, "week");
      endTime = dayjs();
    }

    if (event.target.value === lastMonth) {
      startTime = dayjs().subtract(1, "month");
      endTime = dayjs();
    }

    if (event.target.value === lastYear) {
      startTime = dayjs().subtract(1, "year");
      endTime = dayjs();
      setField(field);
    }

    setField({
      uuid: field.uuid,
      startTime: startTime,
      endTime: endTime,
    });
  };

  const onClickDownload = async () => {
    deviceApi.getDownloadDeviceData(field.uuid, "dispenser", field.startTime.valueOf(), field.endTime.valueOf());
  };

  return (
    <Modal
      title={`Download Device ${props.device.name} Data`}
      open={props.open}
      onCancel={props.onCancel}
      footer={null}
    >
      <br />
      <Space direction={"vertical"} size={"large"} style={{ width: "100%" }}>
        <Radio.Group onChange={onClickButton} style={{ width: "100%" }}>
          <Radio.Button value={LastDay}>Last Day</Radio.Button>
          <Radio.Button value={lastWeek}>Last Week</Radio.Button>
          <Radio.Button value={lastMonth}>Last Month</Radio.Button>
          <Radio.Button value={lastYear}>Last Year</Radio.Button>
        </Radio.Group>
        <RangePicker value={[dayjs(field.startTime), dayjs(field.endTime)]} style={{ width: "100%" }} />
        <Button type={"primary"} block onClick={onClickDownload}>
          Download
        </Button>
      </Space>
    </Modal>
  );
};

export default DeviceDataDownloadModal;
