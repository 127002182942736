import { Navigate, RouteObject } from "react-router-dom";
import SignInPage from "./pages/SignInPage/SignInPage";
import PrivatePage from "./pages/private-page/PrivatePage";
import React from "react";
import DeviceDetailPage from "./pages/DeviceDetailPage/DeviceDetailPage";
import DevicesPage from "./pages/DevicesPage/DevicesPage";
import DispenserPage from "./pages/DispenserPage/DispenserPage";
import PromotionPage from "./pages/PromotionPage/PromotionPage";

export const defaultPath = "/";
export const signInPath = "/sign-in";
export const privatePath = "/private";
export const dispenserPath = "/private/dispensers";
export const devicesPath = "/private/devices";
export const generateDeviceDetailPath = (uuid: string) => {
  return `/private/devices/${uuid}`;
};

export const promotionPath = "/private/promotions";

const routes: RouteObject[] = [
  {
    path: defaultPath,
    children: [
      {
        path: signInPath,
        element: <SignInPage />,
      },
      {
        path: privatePath,
        element: <PrivatePage />,
        children: [
          {
            path: devicesPath,
            element: <DevicesPage />,
          },
          {
            path: "/private/devices/:deviceUuid",
            element: <DeviceDetailPage />,
          },
          {
            path: dispenserPath,
            element: <DispenserPage />,
          },
          {
            path: promotionPath,
            element: <PromotionPage />,
          },
          //     {
          //       path: '/private/dashboard',
          //       element:
          //         <DashboardPage />
          //     },
          //     {
          //       path: '/private/account',
          //       element: <AccountPage />
          //     },
          //     {
          //       path: '/private/profile',
          //       element:
          //         <DashboardPage />
          //     }
          //
        ],
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

export default routes;
